import { reportError, messages } from '@wix/editor-elements-corvid-utils';
import {
  FileType,
  FileMetaData,
  IFileUploaderImperativeActions,
  IFileUploaderProps,
} from '../FileUploader.types';
import {
  ValidationData,
  OnValidateArgs,
  getFileValidityValidationMessage,
  fileTypeToBaseFileType,
} from '../../../core/corvid/inputUtils';
import { parseMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';

type NonEmptyArray<T> = [T, ...Array<T>];

type UploadedFileData = {
  media_type: MediaType;
  file_name: string;
  original_file_name: string;
  height?: number;
  width?: number;
  file_output?: {
    image: Array<{
      url: string;
      height: number;
      width: number;
    }>;
  };
  file_input?: {
    duration: number;
  };
};

type ProcessedFileData = UploadedFileData & {
  uri?: string;
  title?: string;
  filename?: string;
  posterUri?: string;
  posterWidth?: number;
  posterHeight?: number;
  duration?: number;
};

export type SuccessfulUploadResponse = NonEmptyArray<UploadedFileData>;
export type FailedUploadResponse = {
  error_code: number;
  error_description: string;
};

type MediaType = 'picture' | 'document' | 'video' | 'music';

const IMAGE_URI = 'image://';
const DOC_URI = 'wix:document://';
const VIDEO_URI = 'wix:video://';
const AUDIO_URI = 'wix:audio://';
const VERSION = 'v1';

const wixCodeImgUri = ({ uri, width, height, title }: ProcessedFileData) =>
  IMAGE_URI + VERSION + `/${uri}/${width}_${height}/${title}`;
const wixCodeDocUri = ({ uri, filename }: ProcessedFileData) =>
  DOC_URI + VERSION + `/${uri}/${filename}`;
const wixCodeAudioUri = ({ uri, filename, duration }: ProcessedFileData) =>
  AUDIO_URI + VERSION + `/${uri}/${filename}#duration=${duration}`;
const wixCodeVideoUri = ({
  uri,
  filename,
  posterUri,
  posterWidth,
  posterHeight,
}: ProcessedFileData) =>
  VIDEO_URI +
  VERSION +
  `/${uri}/${filename}#posterUri=${posterUri}&posterWidth=${posterWidth}&posterHeight=${posterHeight}`;

const noFileToUploadMessage = 'No File To Upload';

const fileTypeToMediaType = (fileType: FileType, fileName: string) => {
  const mediaTypesTranslation = {
    Image: 'picture',
    Document: 'document',
    Video: 'video',
    Audio: 'music',
  } as const;

  return mediaTypesTranslation[fileTypeToBaseFileType(fileType, fileName)];
};

export const isValidFileType = (_fileType: string) => {
  const fileType =
    _fileType.charAt(0).toUpperCase() + _fileType.slice(1).toLowerCase();
  const validFileTypes = ['Image', 'Document', 'Video', 'Audio', 'Gallery'];
  if (!validFileTypes.includes(fileType)) {
    reportError(
      messages.invalidEnumValueMessage({
        functionName: 'fileType',
        propertyName: 'fileType',
        value: _fileType,
        enum: validFileTypes,
        index: undefined,
      }),
    );
    return false;
  }
  return true;
};

export const getFileDataByType = (
  fileType: FileType,
  uploadedFileData: UploadedFileData,
) => {
  const baseFileType = fileTypeToBaseFileType(
    fileType,
    uploadedFileData.file_name,
  );
  switch (baseFileType) {
    case 'Image':
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        title: uploadedFileData.file_name,
      };
    case 'Document':
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        filename: uploadedFileData.original_file_name,
      };
    case 'Video':
      const posterImages = uploadedFileData.file_output?.image || [];
      const poster01 = posterImages[1] || { url: '' };
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        filename: uploadedFileData.original_file_name,
        posterUri: poster01.url.replace('media/', ''),
        posterWidth: poster01.width,
        posterHeight: poster01.height,
      };
    case 'Audio':
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        filename: uploadedFileData.original_file_name,
        duration: uploadedFileData.file_input?.duration || 0,
      };
    default:
      return uploadedFileData;
  }
};

export const getWixCodeURI = (fileData: ProcessedFileData): string => {
  const mediaType = fileData.media_type;
  switch (mediaType) {
    case 'picture':
      return wixCodeImgUri(fileData);
    case 'music':
      return wixCodeAudioUri(fileData);
    case 'video':
      return wixCodeVideoUri(fileData);
    case 'document':
      return wixCodeDocUri(fileData);
    default:
      throw new TypeError(`Unknown media_type "${mediaType}"`);
  }
};

export const getFileInfo = (mediaType: MediaType, url: string) => {
  switch (mediaType) {
    case 'picture':
      const imageMediaSrc = parseMediaSrc(url, 'image');
      if (imageMediaSrc.error) {
        return null;
      }
      return {
        url,
        mediaId: imageMediaSrc.mediaId,
        title: imageMediaSrc.title,
        width: imageMediaSrc.width,
        height: imageMediaSrc.height,
      };
    case 'document':
      const documentMediaSrc = parseMediaSrc(url, 'document');
      if (documentMediaSrc.error) {
        return null;
      }
      return {
        url,
        mediaId: documentMediaSrc.mediaId,
        title: documentMediaSrc.title,
      };
    case 'video':
      const videoMediaSrc = parseMediaSrc(url, 'video');
      if (videoMediaSrc.error) {
        return null;
      }
      return {
        url,
        mediaId: videoMediaSrc.mediaId,
        title: videoMediaSrc.title,
        width: videoMediaSrc.width,
        height: videoMediaSrc.height,
      };
    case 'music':
      const musicMediaSrc = parseMediaSrc(url, 'audio');
      if (musicMediaSrc.error) {
        return null;
      }
      return {
        url,
        mediaId: musicMediaSrc.mediaId,
        duration: musicMediaSrc.duration,
        title: musicMediaSrc.title,
      };
    default:
      throw new TypeError(`Unknown media_type "${mediaType}"`);
  }
};

const getErrorCode = (validity: ValidationData['validity']) => {
  if (validity.fileTypeNotAllowed) {
    return -7751;
  }
  if (validity.fileSizeExceedsLimit) {
    return -7752;
  }

  return -1;
};

export const getErrorObject = (validationData: ValidationData) => {
  const errorCode = getErrorCode(validationData.validity);
  const errorDescription =
    errorCode === -1 ? noFileToUploadMessage : validationData.validationMessage;

  return {
    errorCode,
    errorDescription,
  };
};

export const startFileUpload = async ({
  resolve,
  fileType,
  handlers,
  fileList,
  onFailedUpload,
  onSuccessfulUpload,
}: {
  resolve: (resolvedValue: any) => void;
  fileType: FileType;
  handlers: any;
  fileList: FileList;
  onFailedUpload: (uploadResponse: FailedUploadResponse) => void;
  onSuccessfulUpload: (uploadResponse: SuccessfulUploadResponse) => void;
}) => {
  const mediaAuthToken = (await handlers.getMediaAuthToken()) || '';
  const file = fileList[0];
  const mediaType = fileTypeToMediaType(fileType, file.name);

  const getUploadUrlResponse = await getUploadUrl({
    file,
    mediaAuthToken,
    mediaType,
  });
  const getUploadUrlResponseJson = await getUploadUrlResponse.json();
  if (!getUploadUrlResponse.ok) {
    return onFailedUpload(getUploadUrlResponseJson);
  }

  const uploadFileToMediaResponse = await uploadFileToMediaManager({
    uploadUrl: getUploadUrlResponseJson.upload_url,
    file,
    mediaType,
  });

  if (!uploadFileToMediaResponse.ok) {
    return onFailedUpload(uploadFileToMediaResponse.response);
  }

  return resolve(onSuccessfulUpload(uploadFileToMediaResponse.response));
};

const getUploadUrl = ({
  file,
  mediaType,
  mediaAuthToken,
}: {
  file: File;
  mediaType: MediaType;
  mediaAuthToken: string;
}) => {
  const getUrl = `https://files.wix.com/site/media/files/upload/url?media_type=${mediaType}&file_name=${encodeURIComponent(
    file.name,
  )}&mime_type=${encodeURIComponent(file.type)}`;

  return fetch(getUrl, {
    method: 'GET',
    headers: {
      Authorization: `APP ${mediaAuthToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

const uploadFileToMediaManager = ({
  uploadUrl,
  file,
  mediaType,
}: {
  uploadUrl: string;
  file: File;
  mediaType: MediaType;
}): Promise<{
  ok: boolean;
  response: any;
}> => {
  const formData = new FormData();
  formData.append('media_type', mediaType);
  formData.append('file', file);
  formData.append('parent_folder_id', 'visitor-uploads');

  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.onload = () => {
      if (xhr.status !== 200) {
        resolve({
          ok: false,
          response: xhr.response,
        });
      }

      resolve({
        ok: true,
        response: xhr.response,
      });
    };

    xhr.open('POST', uploadUrl);
    xhr.send(formData);
  });
};

export const onValidate = ({
  viewerSdkAPI: api,
  validationDataResult,
}: OnValidateArgs<IFileUploaderProps, IFileUploaderImperativeActions>) => {
  if (
    validationDataResult.type === 'FileUploader' &&
    api.props.uploadStatus === 'Not_Started'
  ) {
    const isInvalidToUpload = Object.entries(
      validationDataResult.validity,
    ).some(
      ([key, value]) => value && key !== 'valid' && key !== 'fileNotUploaded',
    );

    const filesValidationInfo = validationDataResult.filesValidationInfo;
    const newValue: Array<FileMetaData> = api.props.value.map((file, idx) => ({
      name: file.name,
      size: file.size,
      valid: !filesValidationInfo[idx],
      validityInfo: {
        invalidKey: filesValidationInfo[idx],
        invalidInfo: getFileValidityValidationMessage(
          filesValidationInfo[idx],
          file.name,
          api.props.fileType,
        ),
      },
      fileInfo: file.fileInfo,
      uploadStatus: file.uploadStatus,
    }));
    api.setProps({
      value: newValue,
      isInvalidToUpload,
      validationMessage: validationDataResult.validationMessage,
    });
  }
};
